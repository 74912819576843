.breath {
  /* margin-left: max(10%, 100px);
  padding-left: 40px; */
  justify-content: center;
}

.counters {
  background-color: var(--dark-mode-buttons-header);
  border-radius: 15px;
  color: white;
  display: flex;
  flex-direction: column;
  width: max(20%, 220px);
  height: 200px;
  padding: 20px;
  margin: 0px 30px 0px 30px;
}

.counters-options {
  font-size: 20px;
  font-weight: 500;
}

.float-left {
  text-align: start;
}

.float-right {
  text-align: end;
}

.counters-input {
  width: 45px;
}

tr {
  height: 45px;
}

.start-button {
  background-color: var(--dark-mode-text);
  padding: 15px;
  width: 300px;
  margin-top: 20px;
  border: none;
  border-radius: 10px;
  color: var(--dark-mode-buttons-header);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 3px;
}

.start-button:hover {
  cursor: pointer;
  background-color: var(--dark-mode-accent);
}

.action-text {
  height: 40px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  color: var(--dark-mode-text);
  font-size: 20px;
  font-weight: 500;
}

.timer-text {
  height: 40px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  color: var(--dark-mode-text);
  font-size: 20px;
  font-weight: 500;
}

.presets {
  border-radius: 10px;
  border: 2px solid var(--dark-mode-white);
  color: var(--dark-mode-text);
  padding: 0 20px 0 20px;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.presets-container {
  margin-top: 20px !important;
  display: flex;
  justify-content: center;
  width: 60%;
  margin: auto;
}

.presets-text {
  font-weight: 400;
}

.pie-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36vh;
  height: 36vh;
  max-height: 300px;
  max-width: 300px;
  min-height: 250px;
  min-width: 250px;
}

.inner-timer {
  position: absolute;
  display: block;
  text-align: center;
  color: var(--dark-mode-text);
  font-size: 22px;
  font-weight: 500;
}

.info-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-container {
  font-size: 18px;
  font-weight: 400;
  color: var(--dark-mode-white);
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.highlighted-text {
  color: var(--dark-mode-accent);
  font-weight: 700;
}

.presets-buttons {
  padding: 0px 20px 20px 20px;
}

.buttons-row {
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  padding: 10px 0px 10px 0px;
  margin-left: auto;
  margin-right: auto;
}

.preset-button {
  color: var(--dark-mode-white);
  background-color: var(--dark-mode-buttons-header);
  border: 1px solid var(--dark-mode-white);
  width: 140px;
  height: 35px;
  border-radius: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.preset-button:hover {
  color: var(--dark-mode-main);
  background-color: var(--dark-mode-text);
}

.video-button {
  background-color: var(--video-button);
}

@media (max-width: 860px) {
  .buttons-row {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
  }

  .preset-button {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 650px) {
  .info-container {
    display: block;
  }

  .pie-chart {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .counters {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}