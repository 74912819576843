body {
  background: var(--dark-mode-main);
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --header-green: #104547;
  --menu-blue: #BFD1E5;
  --menu-text-color: #4B5358;
  --item-background: #1C1B1F;
  --selected-green: #3DA35D;
  --selected-green-hover: #2db959;
  
  --dark-mode-main: #002021;
  --dark-mode-buttons-header: #004F52;
  --dark-mode-button-shadow: #00696D;
  --dark-mode-accent: #4CD9DF;
  --dark-mode-text: #BAFCFF;
  --dark-mode-white: #F1FFFF;

  /* chart colors:  */
  --pie-inhale: #1DBDC3;
  --pie-retain: #00A1A5;
  --pie-exhale: #008488;
  --pie-sustain: #00696D;

  --pie-inhale-2: #B8C8C8;
  --pie-retain-2: #B8CCCC;
  --pie-exhale-2: #B8D0D1;
  --pie-sustain-2: #BCD4D5;

  --dark-color-2: #343434;
  --dark-text-2: #DDDDDD;

  --video-button: #195a85;

}