.header {
  background-color: var(--dark-mode-buttons-header);
  height: 50px;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--dark-mode-white);
  z-index: -1;
}

.app-title {
  font-size: 22px;
  font-weight: 700;
  color: var(--dark-mode-text);
  letter-spacing: -1.5px;
  height: 50px;
  margin-bottom: 0px;
}

.app-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 20px;
  height: 50px;
  margin-bottom: 1px;
}

.menu-icon {
  float: left;
  width: 25px;
  height: 25px;
}

.header-icon {
  padding-left: 4px;
  margin-top: 2px;
}

.main-icon {
  margin-top: 25px;
  margin-left: 10px;
}

.menu-icon-container:hover {
  background: var(--dark-mode-text);
  color: var(--dark-mode-buttons-header);
  cursor: pointer;
}

.menu-icon-container {
  display: flex;
  align-items: center;
  height: 50px;
  max-width: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.menu {
  display: block;
  min-height: calc(100vh - 52px);
  float: left;
  position: absolute;
  background-color: var(--dark-mode-buttons-header);
  border-right: 2px solid var(--dark-mode-white);
  transition: width 0.85s;
}

.menu-item-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
}

.menu-item {
  color: var(--dark-mode-white);
  width: 100%;
  height: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;
  visibility: hidden;
}

.menu-item:hover {
  background-color: var(--dark-mode-text);
  cursor: pointer;
  color: var(--dark-mode-buttons-header);
}

.menu-footer {
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 217px);
  margin-top: 5px;
}

.menu-footer-items {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  width: 100%;
}

.title-container {
  display: flex;
  justify-content: center;
  color: var(--dark-mode-text);
  font-size: 40px;
  letter-spacing: -4px;
}

.center {
  display: flex;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.left-info {
  display: flex;
  align-items: center;
}

.right-info {
  display: flex;
}

.login-button {
  margin: 10px 10px 10px 0px;
  color: var(--dark-mode-white);
  background-color: var(--dark-mode-buttons-header);
  border: 1px solid var(--dark-mode-white);
}

.register-button {
  margin: 10px 40px 10px 10px;
  border: none;
  color: var(--dark-mode-main);
  background-color: var(--dark-mode-accent);
}

.button {
  border-radius: 15px;
  height: 30px;
  width: 100px;
  font-weight: 600;
  cursor: pointer;
}

.login-button:hover {
  color: var(--dark-mode-buttons-header);
  background-color: var(--dark-mode-text);
  border: 1px solid var(--dark-mode-text);
}

.register-button:hover {
  color: var(--dark-mode-buttons-header);
  background-color: var(--dark-mode-text);
  border: 1px solid var(--dark-mode-text);
}

.flex-center {
  display: flex;
  justify-content: center;
}

.light-text {
  color: var(--dark-text-2);
}

.lighter-text {
  color: var(--dark-mode-white);
}